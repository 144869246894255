//variables
var markers = [];
var markersLength = 0;
var $infoboxContainer;
var iBox;
var iBoxes  = [];
var map;
var clusterer;
var $map;

// Load AJAX data
function loadAjax(filePath) {
	return $.ajax({
		'async': false,
		'global': false,
		'url': filePath
	}).responseJSON;
}

// Function to initialize maps
var head_office_marker;
function startMaps(data) {
	$('[id^="map"]').each(function(){
		$map = $(this);
		var marker
		var mapData;

		if (data) {
			var mapData = $.ajax({
				// 'async': false,
				'global': false,
				'url': data,
				'dataType': 'json',
				success: function(response) {
					continueMaps(data, $map, response);
				}
			});
		}
		else {
			if ($('.houses-filter__map-switch').length > 0) {
				if (!$('.houses-filter__map-switch').hasClass('active')) {
					return false;
				}
			}

			var data_array = {};
			$.each($(this).data(), function(key, value) {
				data_array[key] = value;
			});

			data_array['WindowWidth'] = $(window).width();
			var mapData = $.ajax({
				//'async': false,
				'global': false,
				'type': 'POST',
				'url': $map.data('mapdata'),
				'data':({data:data_array}),
				'dataType': 'json',
				success: function(response) {
					continueMaps($map.data('mapdata'), $map, response);
				}
			});
		}
	});
}

function calculateCenter() {
	center = map.getCenter();
}

function continueMaps(data, dollarMap, mapData) {
	//added by db, add head office to display center map
	if ($("body").hasClass('contact')){
		if (data == "/resources/data/contact.json" || (!data && dollarMap.data('mapdata') == "/resources/data/contact.json")) {
			head_office_marker = mapData.markers[0];
		}
		else if (data == "/resources/data/centre-a-mapdata.php") {
			mapData.markers.push(head_office_marker);
			mapData.mapOptions.zoom = 10;
		}
	}

	var src;
	var content;
	var clusterer;
	var center;

	markersLength = mapData.markers.length;
	$infoboxContainer = $(dollarMap.data('infobox-container'));

	map = new google.maps.Map(dollarMap.get(0), mapData.mapOptions);
	if (dollarMap.data('centermarker')) {
		new google.maps.Marker({
			position: mapData.mapOptions.center,
			map: map
		});
	}

	if(mapData.polygons) {
		src = mapData.polygons[0].src;
		loadKmlLayer(src, map);
		function loadKmlLayer(src, map) {
			var kmlLayer = new google.maps.KmlLayer(src, {
				map: map
			});
		}
	}
	set_markers(mapData);
	calculateCenter();

	map.addListener('zoom_changed', function() {
		toggleInfoboxes(map, markers,iBoxes);
	});

	google.maps.event.addDomListener(map, 'idle', function() {
		calculateCenter();
	});
	google.maps.event.addDomListener(window, 'resize', function() {
		//map.setCenter(center);
		toggleInfoboxes(map, markers);
		calculateCenter();
	});

	google.maps.event.addDomListener(map, 'bounds_changed', function() {
		//console.log(map.center.lat(),map.center.lng());
	});

	if (dollarMap.data('centermap')) {
		// move the map to the center as specified in json data
		map.setCenter(new google.maps.LatLng(mapData.mapOptions.center.lat, mapData.mapOptions.center.lng));
		calculateCenter();
	}

	//house and land
	$('.houses-filter__panel-switch').removeClass('ajaxing');
}

function set_markers(mapData, display_center_toggle){
	display_center_toggle = display_center_toggle || false;

	iBoxes = [];
	markers = [];
	var displaymarkers = [];

	var mapBounds = map.getBounds();
	if (mapBounds != undefined){
		var lat0 = mapBounds.getNorthEast().lat();
		var lng0 = mapBounds.getNorthEast().lng();
		var lat1 = mapBounds.getSouthWest().lat();
		var lng1 = mapBounds.getSouthWest().lng();
	}

	if (typeof mapData.markers != 'undefined') {
		markersLength = mapData.markers.length;

		if(markersLength < 1) {
			// $map.css("opacity","0");
			$(".zero_results").removeClass("hide");
		}
		else {
			$map.css("opacity","1");
			$(".zero_results").addClass("hide");
		}

		var markerBounds = new google.maps.LatLngBounds();

		for (var i = 0; i < markersLength; i++) {
			content = '';
			switch (mapData.markers[i].infobox.type) {
				case 'pin':
					content = simplePin(mapData.markers[i].infobox.content);
					break;
				case 'simple':
					content = simpleInfobox(mapData.markers[i].infobox.content);
					break;
				case 'image':
					content = imageInfobox(mapData.markers[i].infobox.content);
					break;
				case 'with-popup':
					content = popupInfobox(mapData.markers[i].infobox.content);
					startInfobox($infoboxContainer);
					break;
				case 'complex':
					content = complexInfobox(mapData.markers[i]);
					startInfobox($infoboxContainer);
					break;
				default:
					break;
			};

			marker = new google.maps.Marker({
				position: mapData.markers[i].position,
				map: map,
				content: content,
				icon: '/resources/images/blank.png',
			});

			iBox = new InfoBox({
				alignBottom: true,
				enableEventPropagation: true,
				disableAutoPan: true
			});

			iBox.setContent(marker.content);
			iBox.open(map, marker);

			iBoxes.push(iBox);
			if (mapData.markers[i].infobox.type == 'complex') {
				marker.iBox = iBox;
				iBox.isOpen = true;
			}

			iBox.hide();
			if (display_center_toggle) {
				if (mapData.markers[i].display_center == "true") {
					displaymarkers.push(marker);
				}
			}
			else {
				markerBounds.extend(marker.position);
			}
			markers.push(marker);
		}

		if (display_center_toggle) {
			var NewMapCenter = map.getCenter();
			var latitude = NewMapCenter.lat();
			var longitude = NewMapCenter.lng();
			if (displaymarkers.length > 0) {
				var closest = find_closest_display_center( latitude, longitude , displaymarkers );
				if (closest >= 0) {
					boundPoint1 = new google.maps.LatLng({lat:lat0, lng:lng0});
					boundPoint2 = new google.maps.LatLng({lat:lat1, lng:lng1});

					if (!mapBounds.contains(displaymarkers[closest].position)) {
						markerBounds.extend(displaymarkers[closest].position);
						markerBounds.extend(boundPoint1);
						markerBounds.extend(boundPoint2);
						fit_bounds_map(mapData, markerBounds);
					}
				}
			}
		}
		else {
			if (markers.length == 1) {
				map.setCenter(markers[0].position);
			}
			else {
				fit_bounds_map(mapData, markerBounds);
			}
		}
		cluster_map();
		//house and land animation
		setTimeout(function() {
			$('.tabs-tertiary__tab-active').fadeTo(500, 1);
		}, 500);
	}
}

function cluster_map() {
	if (markers.length) {
		clusterer = new MarkerClusterer(map, markers, {
			minimumClusterSize: 2,
			radius:100,
			averageCenter: true,
			maxZoom: 13,
			zoomOnClick: false,
			styles: [{
				textColor: 'white',
				width: 50,
				height: 50,
				url: '/resources/images/cluster.png',
				textSize: 14
			}]
		});
		clusterer.setCalculator(function(markers, numStyles) {
			var index = 0;
			var count = markers.length;
			var dv = count;
			while (dv !== 0) {
				dv = parseInt(dv / 10, 10);
				index++;
			}

			index = Math.min(index, numStyles);
			$.each(markers, function(index, value) {
				if ($(value.content).not('.infobox-content').find('.infobox-counter').length) {
					count += parseInt($(value.content).not('.infobox-content').find('.infobox-counter').text()) - 1;
				}
			});

			//count = 10;
			return {
				text: count,
				index: index
			};
		});
		toggleInfoboxes(map, markers,iBoxes);
	}
}
function fit_bounds_map(mapData, markerBounds) {
	if (typeof mapData.fitBounds != 'undefined' && typeof markerBounds != 'undefined') {
		map.fitBounds(markerBounds);
	}
}

function toggleInfoboxes(map, markers) {
	setTimeout(function() {
		for (var i = 0; i < markers.length; i++) {
			var marker_get_map = markers[i].getMap();
			if (marker_get_map != null && markers[i].iBox && !markers[i].iBox.isOpen) {
				markers[i].iBox.open(map);
				markers[i].iBox.isOpen = true;
				iBoxes[i].show();
			}
			else if (marker_get_map == null && markers[i].iBox) {
				markers[i].iBox.close();
				markers[i].iBox.isOpen = false;
				iBoxes[i].hide();
			}
			else if (marker_get_map == null) {
				iBoxes[i].hide();
			}
			else {
				iBoxes[i].show();
			}
		}
	}, 500);
}
// Creates data for simple infobox
function simplePin(infoboxContent) {
	return '<i class="' + infoboxContent.icon + '"></i>';
}

// Creates data for simple infobox
function simpleInfobox(infoboxContent) {
	return '<div class="infobox-tag"><i class="' + infoboxContent.icon + '"></i>' + '<strong>' + infoboxContent.title + '</strong></div>';
}

// Similar to simpleinfobox, but has white background and is an image.
function imageInfobox(infoboxContent) {
	var innerHtml, innerHtmlType;
	if (typeof infoboxContent.image != 'undefined') {
		if (infoboxContent.image.length > 0) innerHtmlType = "image";
	}
	else if (typeof infoboxContent.title != 'undefined') {
		if (infoboxContent.title.length > 0) innerHtmlType = "text";
	}

	if (innerHtmlType == 'image') innerHtml = "<img src=\"" + infoboxContent.image + "\" style=\"display: block; width: 100%; \" />";
	else if (innerHtmlType == 'text') innerHtml = "<strong>" + infoboxContent.title + "</strong>";

	return '<div class="infobox-tag infobox-tag-white">' + innerHtml + '</div>';
}

// Creates data for infobox with popup
function popupInfobox(infoboxContent) {
	var infobox_cls = "";
	if( infoboxContent.woh == "yes") {
		infobox_cls = "infobox-tag-darkgrey";
	}
	else if (infoboxContent.estate == "yes") {
		infobox_cls = "infobox-tag-white";
	}

	var aTarget = '';
	if (infoboxContent.popupBtnTarget == "blank") {
		var aTarget = 'target="_blank"';
	}

	var extraInfo = "";
	if (infoboxContent.popupExtraInfo != "") {
		extraInfo = "<p style='font-weight: 500;color: #231f20;'>"+infoboxContent.popupExtraInfo+"</p>";
	}
	return '<div class="infobox-tag '+ infobox_cls
			+ ' infobox-expand" data-lat="' + infoboxContent.lat
			+ '" data-lng="' + infoboxContent.lng
			+ '" ><strong>' + infoboxContent.title
			+ '</strong></div><div class="infobox-content"><div class="infobox-image" style="background-image: url(' + infoboxContent.popupImg
			+ ');"></div><div class="infobox-inner"><strong>' + infoboxContent.popupTitle
			+  '</strong><p>' + infoboxContent.popupText
			+  '</p><a href="tel:' + infoboxContent.popupPhone
			+  '">' + infoboxContent.popupPhone
			+ '</a><p>' + infoboxContent.popupTextSec
			+ '</p>' + extraInfo
			+ '<a href="' + infoboxContent.popupBtnLink
			+ '" class="infobox-btn" ' + aTarget
			+ ' >' + infoboxContent.popupBtnText
			+ '</a></div></div>';
}

// Creates data for complex infobox
function complexInfobox(infoboxContent) {
	var houseCount = infoboxContent.properties.length;
	if (typeof infoboxContent.houseCount != 'undefined') houseCount = infoboxContent.houseCount;

	var fHouseCount = "";
	if (infoboxContent.properties.hasOwnProperty("fHouseCount")) {
		fHouseCount = '<p>' + infoboxContent.properties[i].fHouseCount + '</p>';
	}

	var wrappers = '<div class="infobox-tag infobox-expand infobox-cluster" data-lat="' + infoboxContent.position.lat
					+ '" data-lng="' + infoboxContent.position.lng
					+ '"><strong class="infobox-counter">' + houseCount
					+ '</strong></div><div class="infobox-content infobox-content-secondary">'
					+'<header class="infobox-head"><strong class="infobox-counter">' + houseCount
					+ '</strong> packages available at ' + infoboxContent.estate
					+ '</header><div class="infobox-body">';
	var content = '';

	for (var i = 0; i < infoboxContent.properties.length; i++) {
		content += '<div class="property " data-href="' + infoboxContent.properties[i].propertyLink + '"><div class="property-image">';
		if (typeof infoboxContent.properties[i].propertyImage != 'undefined') {
			content += '<img src="' + infoboxContent.properties[i].propertyImage + '" />';
		}
		content += '</div><div class="property-content"><h3>' + infoboxContent.properties[i].propertyTitle
				+ ' <em>(' + infoboxContent.properties[i].propertySize
				+ ' m<sup>2</sup>)</em></h3><p>' + infoboxContent.properties[i].propertyPrice
				+ '</p>' + fHouseCount
				+ '<p>' + infoboxContent.properties[i].propertyLocation
				+ '</p><div class="property-actions">';
		content += '<ul><li><a class="btn btn-red btn-red-transparent" href="' + infoboxContent.properties[i].propertyLink
				+ '">View Package</a></li></ul></div></div></div>';
	}

	return wrappers + content + '</div></div>';
}

// Starts infobox with popup
function startInfobox($infoboxContainer) {
	$(document).on('click', '.infobox-expand', function(e){
		e.preventDefault();

		if ($infoboxContainer.hasClass('no-recenter-onclick') && $(this).hasClass('infobox-cluster')) {
			var latlng = new google.maps.LatLng(parseFloat($(this).data("lat")), parseFloat($(this).data("lng")));
			offsetCenter(latlng, -100,0);
		}
		else {
			if ($(window).width() < 768) {
				var offsetX = 0.0002; // move center one quarter map width left
				var offsetY = 0.002; // move center one quarter map height down
				if (map.getZoom() > 10) {
					offsetX = 0;
					offsetY = 0.001;
				}
				if (map.getZoom() > 11) {
					offsetY = 0.001;
				}
				if (map.getZoom() > 12) {
					offsetY = 0;
				}
			}
			else {
				var offsetX = 0.0005; // move center one quarter map width left
				var offsetY = 0.005; // move center one quarter map height down
				if (map.getZoom() > 10) {
					offsetX = 0;
					offsetY = 0.002;
				}
				if (map.getZoom() > 11) {
					offsetY = 0.001;
				}
				if (map.getZoom() > 13) {
					offsetY = 0.0003;
				}
				if (map.getZoom() > 14) {
					offsetY = 0.0001;
				}
				if (map.getZoom() > 15) {
					offsetY = 0.00005;
				}
				if (map.getZoom() > 17) {
					offsetY = 0;
				}
			}

			var center_lat;
			var center_lng;

			center_lat = $(this).data("lat") - $(this).data("lat") * offsetY;
			center_lng = $(this).data("lng") - $(this).data("lng") * offsetX;

			var myLatlng = { lat: center_lat, lng: center_lng };
			map.setCenter(myLatlng);
		}

		var $this = $(this);
		var $parent = $this.parent();

		var $iBox = $parent.find('.infobox-content').clone();
		$('.infobox-expanded').removeClass('infobox-expanded');

		$parent.addClass('infobox-expanded');
		$infoboxContainer.children().remove();
		$infoboxContainer.append($iBox);

		if ($infoboxContainer.find('.infobox-content-secondary').length) {
			$infoboxContainer.addClass('container-shown')
		}
	});

	$(document).on('click touchstart', function(e){
		var $target = $(e.target);

		if(!$target.parent().is('.infobox-cluster') && !$target.is('.infobox-cluster') && $('#gmap-content').hasClass('container-shown')) {
			if ($(window).width() > 767) {
				$('.gmap-content').removeClass('container-shown').children().remove();
			}
		}

		if (!$target.is('.infoBox, .infoBox *, .infobox-content, .infobox-content *')) {
			$('.infoBox').removeClass('infobox-expanded');

			$infoboxContainer.filter(':not(.container-shown)').children().remove();
			setTimeout(function() {
				$infoboxContainer.removeClass('container-shown');
			});
		}
	});
}
$(document).ready(function() {
	$(".show-display-centres").click(function(e) {
		e.preventDefault();

		if($(this).children('input').attr("checked") == "checked") {
			$(this).children().attr("checked",false).removeClass("checked");
			startMaps("/resources/data/contact.json");
		}
		else {
			$(this).children().attr("checked",true).addClass("checked");
			startMaps("/resources/data/centre-a-mapdata.php");
		}
		return false;
	});

	$(".estate_show-display-centres").click(function(e) {
		e.preventDefault();

		var display_center = "";
		var url = $("#map").data("mapdata");

		if ($(this).children('input').attr("checked") == "checked") {
			$(this).children().attr("checked",false).removeClass("checked");
		}
		else {
			$(this).children().attr("checked",true).addClass("checked");
			display_center = "show";
		}

		var mapData = $.ajax({
			'global': false,
			'url': url,
			'data': ({ display_center:display_center }),
			'dataType': 'json',
			'success': function(response) {
					//console.log(response);
					$('.infoBox').remove();
					if (clusterer != undefined) {
						clusterer.clearMarkers();
					}
					markers = [];
					set_markers(response,true);
				}
			});
		return false;
	});
});
function offsetCenter(latlng, offsetx, offsety) {
	// latlng is the apparent centre-point
	// offsetx is the distance you want that point to move to the right, in pixels
	// offsety is the distance you want that point to move upwards, in pixels
	// offset can be negative
	// offsetx and offsety are both optional

	var scale = Math.pow(2, map.getZoom());

	var worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
	var pixelOffset = new google.maps.Point((offsetx/scale) || 0,(offsety/scale) ||0);

	var worldCoordinateNewCenter = new google.maps.Point(
		worldCoordinateCenter.x - pixelOffset.x,
		worldCoordinateCenter.y + pixelOffset.y
	);

	var newCenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

	map.panTo(newCenter);
}

function find_closest_display_center( lat1, lon1 , markers ) {
	var pi = Math.PI;
	var R = 6371; //equatorial radius
	var distances = [];
	var closest = -1;

	for (i = 0; i < markers.length; i++) {
		var lat2 = markers[i].position.lat();
		var lon2 = markers[i].position.lng();

		var chLat = lat2 - lat1;
		var chLon = lon2 - lon1;

		var dLat = chLat * (pi/180);
		var dLon = chLon * (pi/180);

		var rLat1 = lat1 * (pi/180);
		var rLat2 = lat2 * (pi/180);

		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(rLat1) * Math.cos(rLat2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;

		distances[i] = d;
		if (closest == -1 || d < distances[closest]) {
			closest = i;
		}
	}
	return closest;
}